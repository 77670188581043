import sass from '../scss/main.scss';
import Inputmask from "inputmask";
import IMask from 'imask';
import getParents from 'dom-parents';
import MicroModal from 'micromodal';
import BadgerAccordion from 'badger-accordion';
import {event, toggleClass, serialize} from './functions.js';
import Swiper from 'swiper';
import SimpleBar from 'simplebar';
import 'lightgallery.js';
import Choices from 'choices.js';
import reqwest from 'reqwest';

import video_1 from '../video/video_1.mp4';
import video_2 from '../video/video_2.webm';

import favicon from '../img/favicon.png';

import address_1 from '../img/address-1.jpg';
import address_2 from '../img/address-2.jpg';
import address_3 from '../img/address-3.jpg';
import address_4 from '../img/address-4.jpg';
import icon1 from '../img/icon1.png';
import icon2 from '../img/icon2.png';
import icon4 from '../img/icon4.png';
import icon5 from '../img/icon5.png';
import icon6 from '../img/icon6.png';
import icon8 from '../img/icon8.png';
import no_avatar from '../img/no-avatar.jpg';
import review_1 from '../img/review-1.jpg';
import review_2 from '../img/review-2.jpg';
import review_3 from '../img/review-3.jpg';
import review_4 from '../img/review-4.jpg';
import review_5 from '../img/review-5.jpg';
import scan_1 from '../img/scan-1.jpg';
import scan_2 from '../img/scan-2.jpg';
import scan_3 from '../img/scan-3.jpg';
import scan_4 from '../img/scan-4.jpg';
import scan_5 from '../img/scan-5.jpg';
import scan_6 from '../img/scan-6.jpg';
import scan_7 from '../img/scan-7.jpg';
import scan_8 from '../img/scan-8.jpg';
import scan_9 from '../img/scan-9.jpg';
import scan_10 from '../img/scan-10.jpg';
import scan_11 from '../img/scan-11.jpg';
import scan_12 from '../img/scan-12.jpg';

let isMobile = (function (a) {
    return /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(a) || /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(a.substr(0, 4))
})(navigator.userAgent || navigator.vendor || window.opera);

let lastScrollTop = 0;
let currentPosScroll = 0;
let maxPosScroll = 0;
window.onscroll = onScroll;


const simpleBar = new SimpleBar(document.querySelector('.services__table'),
    {
        autoHide: false
    }
);

simpleBar.getScrollElement().addEventListener('scroll', function(e){
    currentPosScroll = simpleBar.getContentElement().scrollTop;
    maxPosScroll = this.scrollHeight-this.clientHeight - 10;

    if (lastScrollTop > currentPosScroll && currentPosScroll == 0) {
        document.querySelector('.services__table').classList.add('overflow-hide');
        simpleBar.recalculate();
    } else if (lastScrollTop < currentPosScroll && currentPosScroll>=maxPosScroll) {
        document.querySelector('.services__table').classList.add('overflow-hide');
        simpleBar.recalculate();
    }
    lastScrollTop = currentPosScroll;

});


function onScroll(e) {
    let top = window.pageYOffset;
    let currentScroll = document.documentElement.scrollTop || document.body.scrollTop;




    //Появление фиксированного меню
    if (currentScroll > 20) {
        if (lastScrollTop > top) {
            document.querySelector('.header').classList.remove('hide');
            document.querySelector('.header').classList.add('vis');
            if(currentPosScroll > 0){
                document.querySelector('.services__table').classList.remove('overflow-hide');
                simpleBar.recalculate();
            }
        } else if (lastScrollTop < top) {
            document.querySelector('.header').classList.add('vis');

            if(currentPosScroll == 0){
               document.querySelector('.services__table').classList.remove('overflow-hide');
               simpleBar.recalculate();
            }
        }
    } else {
        document.querySelector('.header').classList.remove('vis');
    }

    lastScrollTop = top;
}

document.addEventListener("DOMContentLoaded", function () {
    let currentScroll = document.documentElement.scrollTop || document.body.scrollTop;
    if (currentScroll > 20) {
        document.querySelector('.header').classList.add('vis');
    }
    document.querySelector('.header').classList.add('loaded');
});



if(isMobile){
    document.querySelector('.simplebar-offset').classList.add('w-100');
}


new Swiper('.actions__container', {
    loop: false,
    slidesPerView: 1,
    effect: 'fade',
    autoHeight: true,
    navigation: {
        nextEl: '.actions__next',
        prevEl: '.actions__prev',
    },
    pagination: {
        el: '.actions__pagination',
        clickable: true,
    },
    fadeEffect: {
        crossFade: true
    }
});
new Swiper('.reviews__container', {
    loop: true,
    slidesPerView: 1,
    effect: 'fade',
    autoHeight: true,
    navigation: {
        nextEl: '.reviews__next',
        prevEl: '.reviews__prev',
    },
    pagination: {
        el: '.reviews__pagination',
        clickable: true,
    },
    fadeEffect: {
        crossFade: true
    }
});
new Swiper('.info__address-container', {
    loop: true,
    slidesPerView: 1,
    effect: 'fade',
    autoHeight: false,
    navigation: {
        nextEl: '.info__address-next',
        prevEl: '.info__address-prev',
    },
    fadeEffect: {
        crossFade: true
    }
});

const breakpoint = window.matchMedia( '(min-width:576px)' );
let howToWorkSwiper;
let mastersSwiper;

const breakpointChecker = function() {
    if ( breakpoint.matches === true ) {
        if ( howToWorkSwiper !== undefined ) howToWorkSwiper.destroy( true, true );
        if ( mastersSwiper !== undefined ) mastersSwiper.destroy( true, true );
        return;
    } else if ( breakpoint.matches === false ) {
        return enableMobileSwipers();
    }
};

const enableMobileSwipers = function() {
    howToWorkSwiper = new Swiper('.how-to-work__slider', {
        loop: true,
        slidesPerView: 1,
        effect: 'fade',
        autoHeight: false,
        navigation: {
            nextEl: '.how-to-work__next',
            prevEl: '.how-to-work__prev',
        },
        fadeEffect: {
            crossFade: true
        },
    });
    mastersSwiper = new Swiper('.masters__slider', {
        loop: true,
        slidesPerView: 1,
        effect: 'fade',
        autoHeight: false,
        pagination: {
            el: '.masters__pagination',
            clickable: true,
        },
        fadeEffect: {
            crossFade: true
        },
    });
};

breakpoint.addListener(breakpointChecker);
breakpointChecker();

//Маска для полей с телефоном
const inputsTel = document.querySelectorAll('input[type="tel"]');
inputsTel.forEach(element => {
    let phoneMask = new IMask(element, {
        mask: [
            {
                mask: '{8} (000) 000-00-00',
                startsWith: '8',
            },
            {
                mask: '{+7} (000) 000-00-00',
                startsWith: '',
            }
        ],
        dispatch: function (appended, dynamicMasked) {
            let number = (dynamicMasked.value + appended).replace(/\D/g,'');
            return dynamicMasked.compiledMasks.find(function (m) {
                return number.indexOf(m.startsWith) === 0;
            });
        }
    });
});

//Плавная прокрутка
const anchors = document.querySelectorAll('a[href*="#"]');
for (let anchor of anchors) {


    anchor.addEventListener('click', function (e) {
        e.preventDefault();
        let blockID = anchor.getAttribute('href');
        if (blockID != '#') {
            document.querySelector(blockID).scrollIntoView({
                behavior: 'smooth',
                block: 'start'
            })
        }
    })
}


event('.services__more', 'click', function (e) {
    if (this.textContent == 'Весь прайс') {
        this.textContent = 'Свернуть';
    } else {
        this.textContent = 'Весь прайс';
    }
});

document.querySelectorAll('.lightgallery').forEach((el) => {
    lightGallery(el);
});

function disableScrollModals() {
    document.querySelectorAll('.modal__container').forEach((el) => {
        scrollLock.disablePageScroll(el);
    })
}

function enableScrollModals() {
    document.querySelectorAll('.modal__container').forEach((el) => {
        scrollLock.enablePageScroll(el)
    })
}


//Модальное окно
MicroModal.init({
    openTrigger: 'data-custom-open',
    disableScroll: true,
    disableFocus: false,
    awaitCloseAnimation: true,
});

document.querySelector('.main-banner__video video').classList.add('vis');

window.onload = () => {
    //Аккордеон
    new BadgerAccordion('.services__accordion');
    new BadgerAccordion('.questions__accordion');
    document.querySelector('.services__hidden').classList.add('vis');
};

if (document.querySelector('.js-choice') !== null) {
    const choices = new Choices('.js-choice', {
        itemSelectText: '',
        loadingText: 'Загрузка...',
        searchEnabled: false,
        shouldSort: false,
    });
}


event("form", 'submit', function (e) {
    e.preventDefault();
    let serial = serialize(this);

    let messages = {
        true: {
            title: 'Спасибо за заявку!'
        },
        false: {
            title: 'Ошибка!'
        }
    };

    let phone = this.querySelector('[name="phone"]').value;
    /*var captcha = document.querySelector('.captcha-check');

    captcha.querySelector('img').src = 'captcha.php?t=' + new Date().getTime();
    captcha.style.display = 'block';

    event('.captcha-check', 'submit', function (e) {
        let captchaValue = captcha.querySelector('[name="captcha"]').value;
*/
        reqwest({
            url: 'form.php'
            , type: 'json'
            , method: 'POST'
            , data: serial /*+ '&captcha=' + captchaValue*/
            , complete: function (data) {
                /*captcha.style.display = 'none';*/
                if (data.status) {
                    ym(53875555, 'reachGoal', 'OrderDone');
                    gtag('event', 'zayavk', {'event_category': 'button'});
                    if (!!window.send_calltouch){ window.send_calltouch(phone); }
                }
                document.querySelector('#send h2').innerHTML = messages[data.status]['title'];
                document.querySelector('#send .modal__text').innerHTML  = data.message;
                MicroModal.show('send');
            }
        });
    /*});*/


});

let virus = document.querySelector('.virus');
let virusBtn = virus.querySelector('.virus__button');
let virusInfo = virus.querySelector('.virus__info');
let virusClose = virusInfo.querySelector('.virus__info-close');

if (!localStorage.getItem('virusInfoClose') || JSON.parse(localStorage.getItem('virusInfoClose')) === false ) {
    virusInfo.classList.add('virus__info--open');
}

virusBtn.addEventListener('click', function (e) {
    e.preventDefault();
    if (virusInfo.classList.contains('virus__info--open')) {
        virusInfo.classList.remove('virus__info--open');
        localStorage.setItem('virusInfoClose', JSON.stringify(true));
    } else {
        virusInfo.classList.add('virus__info--open');
        localStorage.setItem('virusInfoClose', JSON.stringify(false));
    }
    return false;
});

virusClose.addEventListener('click', function (e) {
    virusInfo.classList.remove('virus__info--open');
    localStorage.setItem('virusInfoClose', JSON.stringify(true));
});
